.left_menu {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   row-gap: 50px;
   width: 100%;
   max-width: 16.771%;
   background: #23482C;
   padding: 20px 30px 30px 30px;
   height: 100dvh;
   overflow-y: auto;
   max-height: 100%;
   position: fixed;
   top: 0px;
   z-index: 10;
   left: 0px;
   transition: .5s;
}

.left_menu svg path {
   transition: .5s;
}

.left_menu.black {
   background: #1C1C1C;
}

@media screen and (max-width: 1199px) {
   .left_menu {
      max-width: 100%;
      position: fixed;
      flex-direction: row;
      align-items: flex-end;
      height: auto;
      overflow: visible;
      padding-left: 17px;
      padding-right: 17px;
      padding-top: 9px;
      padding-bottom: 7px;
      z-index: 99;
   }

   .left_menu.white_bg {
      background: #F3EAD9;
   }

   .left_menu.green {
      background: #23482C;
   }

   .left_menu.black {
      background: #1C1C1C;
   }

   .left_menu.black nav {
      background: #1C1C1C;
   }

   .left_menu.active.white_bg {
      background: #23482C;
   }

   .left_menu.active.white_bg .logo_mobile .switch_color {
      fill: #F3EAD9;
   }
}

@media screen and (max-width: 767px) {
   .left_menu {
      padding-left: 12px;
      padding-right: 12px;
   }
}

.left_menu::-webkit-scrollbar {
   width: 0px;
}

.left_menu .logo {
   display: flex;
   z-index: 21;
   transition: .3s;
}

@media (hover: hover) {
   .left_menu .logo:hover {
      opacity: 0.8;
   }
}

@media (hover: none) {
   .left_menu .logo:active {
      opacity: 0.8;
   }
}

@media screen and (max-width: 1199px) {
   .left_menu .logo {
      width: 215px;
      height: 48px;
   }
}

.left_menu .logo .logo_mobile {
   display: none;
}

@media screen and (max-width: 1199px) {
   .left_menu .logo .logo_mobile {
      display: flex;
   }

   .left_menu .logo .logo_desk {
      display: none;
   }
}

.left_menu .logo svg,
.left_menu .logo img {
   max-width: 100%;
   max-height: 100%;
   width: 100%;
   height: auto;
}

@media screen and (max-width: 1199px) {

   .left_menu .logo svg,
   .left_menu .logo img {
      width: auto;
      height: 100%;
   }
}

@media screen and (max-width: 1199px) {
   .left_menu nav {
      position: fixed;
      top: 0px;
      z-index: 20;
      width: 100%;
      background: #23482C;
      left: -100%;
      padding-left: 17px;
      padding-right: 17px;
      height: 100%;
      max-height: 100%;
      /* opacity: 0;
      visibility: hidden; */
      transition: 0.3s;
      padding-top: 64px;
      padding-bottom: 64px;
   }

   .left_menu nav.active {
      /* opacity: 1;
      visibility: visible; */
      left: 0%;
   }
}

.left_menu nav ul {
   display: flex;
   flex-direction: column;
   align-items: center;
}

@media screen and (max-width: 1199px) {
   .left_menu nav ul {
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      overflow-y: auto;
      height: 100%;
   }
}

.left_menu nav li {
   display: flex;
}

.left_menu nav a {
   color: #F3EAD9;
   text-align: center;
   font-size: clamp(1.25rem, -1.25rem + 2.5vw, 1.75rem);
   font-style: normal;
   font-weight: 600;
   line-height: clamp(1.75rem, -0.75rem + 2.5vw, 2.25rem);
   transition: 0.3s;
}

@media (hover: hover) {
   .left_menu nav a:hover {
      color: #29D300;
   }
}

@media (hover: none) {
   .left_menu nav a:active {
      color: #29D300;
   }
}

@media screen and (max-width: 1600px) {
   .left_menu nav a {
      font-size: clamp(1rem, -0.25rem + 1.667vw, 1.25rem);
      line-height: clamp(1.5rem, 0.25rem + 1.667vw, 1.75rem);
   }
}

@media screen and (max-width: 1199px) {
   .left_menu nav a {
      color: #F3EAD9;
      font-family: "Spektra", sans-serif;
      font-size: 50px;
      font-style: normal;
      font-weight: 200;
      line-height: 96%;
   }
}

.left_menu .left_tel {
   width: 100%;
}

.left_menu .left_tel a {
   display: inline-flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   padding: 13px 0px 16px 0;
   border: 1px solid #29D300;
   color: #F3EAD9;
   text-align: center;
   font-size: clamp(1.25rem, -1.25rem + 2.5vw, 1.75rem);
   font-style: normal;
   font-weight: 600;
   line-height: 38px;
   transition: 0.3s;
}

@media (hover: hover) {
   .left_menu .left_tel a:hover {
      background: #29D300;
      color: #1C1C1C;
   }
}

@media (hover: none) {
   .left_menu .left_tel a:active {
      background: #29D300;
      color: #1C1C1C;
   }
}

@media screen and (max-width: 1600px) {
   .left_menu .left_tel a {
      font-size: clamp(1rem, -0.25rem + 1.667vw, 1.25rem);
      padding-top: clamp(0.5rem, -0.438rem + 1.25vw, 0.688rem);
      padding-bottom: clamp(0.625rem, -0.313rem + 1.25vw, 0.813rem);
   }
}

@media screen and (max-width: 1199px) {
   .left_menu .left_tel {
      display: none;
   }
}

.left_menu .btn_burger {
   display: none;
   justify-content: center;
   align-items: center;
   width: 40px;
   height: 40px;
   min-width: 40px;
   min-height: 40px;
   border-radius: 50%;
   background: rgb(243, 234, 217);
   z-index: 21;
}

.left_menu .btn_burger .burger_trigger span {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 24px;
   height: 4px;
   background: #23482C;
   position: relative;
   transition: 0.3s;
}

.left_menu .btn_burger .burger_trigger span::before {
   content: "";
   display: block;
   width: 100%;
   height: 4px;
   position: absolute;
   top: 6px;
   left: 0;
   background: #23482C;
   transition: inherit;
}

.left_menu .btn_burger .burger_trigger span::after {
   content: "";
   display: block;
   width: 100%;
   height: 4px;
   position: absolute;
   bottom: 6px;
   left: 0;
   background: #23482C;
   transition: inherit;
}

.left_menu .btn_burger .burger_trigger.active span {
   transform: rotate(45deg);
}

.left_menu .btn_burger .burger_trigger.active span::before {
   top: 0px;
   transform: rotate(-90deg);
}

.left_menu .btn_burger .burger_trigger.active span::after {
   opacity: 0;
}

@media screen and (max-width: 1199px) {
   .left_menu .btn_burger {
      display: flex;
   }
}

.green_logo .logo_mobile .swtich {
   fill: #23482C;
}

.white_logo .logo_mobile .swtich {
   fill: #F3EAD9;
}

.left_menu.active .green_logo .logo_mobile .swtich {
   fill: #F3EAD9;
}