.other_brands .section__left_title {
   padding-top: 23px;
}

@media screen and (max-width: 1600px) {
   .other_brands .section__left_title {
      padding-top: 17px;
   }
}

@media screen and (max-width: 1199px) {
   .other_brands .section__left_title {
      padding-top: 16px;
   }
}

.other_brands .section__left_title h2 {
   color: #F2E9D8;
   font-family: "Spektra", sans-serif;
   font-size: 88px;
   font-style: normal;
   font-weight: 200;
   line-height: 107%;
   text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
   .other_brands .section__left_title h2 {
      font-size: 75px;
      line-height: 102%;
   }
}

@media screen and (max-width: 1199px) {
   .other_brands .section__left_title h2 {
      font-size: 50px;
      line-height: 96%;
   }
}

.other_brands .sw_other_brands {
   margin-top: 23px;
   margin-bottom: 52px;
}

@media screen and (max-width: 1600px) {
   .other_brands .sw_other_brands {
      margin-top: 16px;
      margin-bottom: 46px;
   }
}

@media screen and (max-width: 1199px) {
   .other_brands .sw_other_brands {
      margin-bottom: 37px;
   }
}

@media screen and (max-width: 767px) {
   .other_brands .sw_other_brands {
      overflow: visible;
   }
}

.other_brands .sw_other_brands .item {
   display: flex;
   flex-direction: column;
   row-gap: 9px;
}

@media screen and (max-width: 1600px) {
   .other_brands .sw_other_brands .item {
      row-gap: 12px;
   }
}

@media screen and (max-width: 1199px) {
   .other_brands .sw_other_brands .item {
      row-gap: 11px;
   }
}

@media screen and (max-width: 767px) {
   .other_brands .sw_other_brands .item {
      max-width: 300px;
   }
}

.other_brands .sw_other_brands .item .image {
   width: 100%;
   height: auto;
   overflow: hidden;
   aspect-ratio: 502.65/500;
   border-radius: 5px;
}

@media (hover: hover) {
   .other_brands .sw_other_brands .item .image:hover img {
      transform: scale(1.03);
   }
}

@media (hover: none) {
   .other_brands .sw_other_brands .item .image:active img {
      transform: scale(1.03);
   }
}

@media screen and (max-width: 1600px) {
   .other_brands .sw_other_brands .item .image {
      aspect-ratio: 1/1;
   }
}

@media screen and (max-width: 1199px) {
   .other_brands .sw_other_brands .item .image {
      aspect-ratio: 238/240;
   }
}

@media screen and (max-width: 767px) {
   .other_brands .sw_other_brands .item .image {
      aspect-ratio: 300/304;
   }
}

.other_brands .sw_other_brands .item .image img {
   height: auto;
   border-radius: 5px;
   transition: 0.55s;
}

@media screen and (max-width: 1600px) {
   .other_brands .sw_other_brands .item .image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
   }
}

.other_brands .sw_other_brands .item h3 {
   color: #F2E9D8;
   font-size: 30px;
   font-style: normal;
   font-weight: 600;
   line-height: 39px;
   text-transform: uppercase;
   transition: 0.3s;
}

@media (hover: hover) {
   .other_brands .sw_other_brands .item h3:hover {
      color: #29D300 !important;
   }
}

@media (hover: none) {
   .other_brands .sw_other_brands .item h3:active {
      color: #29D300 !important;
   }
}

@media screen and (max-width: 1600px) {
   .other_brands .sw_other_brands .item h3 {
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
   }
}

@media screen and (max-width: 1199px) {
   .other_brands .sw_other_brands .item h3 {
      font-size: 14px;
      line-height: 16px;
   }
}