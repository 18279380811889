@charset "UTF-8";



/* ОБНУЛЕНИЕ */
/**
  Нормализация блочной модели
 */
*,
*::before,
*::after {
   box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   font: inherit;
   vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
   display: block;
}

body {
   line-height: 1;
}

ol,
ul {
   list-style: none;
}

blockquote,
q {
   quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
   content: "";
   content: none;
}

table {
   border-collapse: collapse;
   border-spacing: 0;
}

/**
    Упрощаем работу с изображениями
   */
img {
   display: block;
   max-width: 100%;
   width: 100%;
   height: 100% !important;
   object-fit: cover;
}

/**
    Наследуем свойства шрифт для полей ввода
   */
input,
textarea,
select,
button {
   background: none;
   outline: none;
   border: none;
   padding: 0;
   margin: 0;
   border-radius: 0px;
   font: inherit;
}

html {
   /**
    Пригодится в большинстве ситуаций
    (когда, например, нужно будет "прижать" футер к низу сайта)
   */
   height: 100%;
   /**
    Плавный скролл
   */
   scroll-behavior: smooth;
}

body {
   /**
    Пригодится в большинстве ситуаций
    (когда, например, нужно будет "прижать" футер к низу сайта)
   */
   min-height: 100%;
   /**
    Унифицированный интерлиньяж
   */
   line-height: 1.5;
}

div {
   margin: 0;
   padding: 0;
}

a {
   text-decoration: none;
}



svg,
svg path,
svg rect,
svg circle,
svg line {
   transition: 0.3s;
}

.icon {
   display: flex;
}

.brand_list.page {
   padding-top: 40px;
   padding-bottom: 44px;
}

.loader {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100vw;
   height: 100dvh;
   background: #23482C;
   position: fixed;
   top: 0;
   left: 0;
   animation-name: loading;
   animation-duration: 2s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
}

.loader span {
   position: absolute;
   opacity: 0;
   font-size: 1px;
}

@keyframes loading {
   0% {
      opacity: 1;
   }

   50% {
      opacity: .5;
   }

   100% {
      opacity: 1;
   }
}

@media screen and (max-width: 1600px) {
   .brand_list.page {
      padding-top: 30px;
      padding-bottom: 77px;
   }
}

@media screen and (max-width: 1199px) {
   .brand_list.page {
      padding-top: 24px;
      padding-bottom: 43px;
   }
}

@media screen and (max-width: 767px) {
   .brand_list.page {
      row-gap: 44px;
      padding-bottom: 36px;
   }
}

.brand_list.page .item {
   display: flex;
   flex-direction: column;
   row-gap: 20px;
}

@media screen and (max-width: 1600px) {
   .brand_list.page .item {
      row-gap: 23px;
   }
}

@media screen and (max-width: 1199px) {
   .brand_list.page .item {
      row-gap: 12px;
   }
}

@media screen and (max-width: 767px) {
   .brand_list.page .item {
      row-gap: 12px;
   }
}

.brand_list.page .item .image {
   width: 100%;
   height: auto;
   overflow: hidden;
   aspect-ratio: 1/0.9994;
}

@media (hover: hover) {
   .brand_list.page .item .image:hover img {
      transform: scale(1.03);
   }
}

@media (hover: none) {
   .brand_list.page .item .image:active img {
      transform: scale(1.03);
   }
}

@media screen and (max-width: 1600px) {
   .brand_list.page .item .image {
      aspect-ratio: 1/0.98953;
   }
}

@media screen and (max-width: 1199px) {
   .brand_list.page .item .image {
      aspect-ratio: 1/1;
   }
}

@media screen and (max-width: 767px) {
   .brand_list.page .item .image {
      aspect-ratio: 1/1.00285;
   }
}

.brand_list.page .item .image img {
   height: auto;
   border-radius: 5px;
   transition: 0.55s;
}

@media screen and (max-width: 1600px) {
   .brand_list.page .item .image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
   }
}

.brand_list.page .item h3 {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   text-transform: uppercase;
   transition: 0.3s;
}

@media (hover: hover) {
   .brand_list.page .item h3:hover {
      color: #29D300 !important;
   }
}

@media (hover: none) {
   .brand_list.page .item h3:active {
      color: #29D300 !important;
   }
}

@media screen and (max-width: 1600px) {
   .brand_list.page .item h3 {
      font-size: 18px;
      line-height: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .brand_list.page .item h3 {
      font-size: 14px;
      line-height: normal;
   }
}

@media screen and (max-width: 767px) {
   .brand_list.page .item h3 {
      font-size: 20px;
      line-height: normal;
   }
}

html,
body {
   overflow-x: clip;
   scroll-behavior: unset !important;
}

body {
   display: flex;
   color: #464646;
   font-family: "Spektra", sans-serif;
   background-color: #23482C;
   font-size: 16px;
   line-height: normal;
   font-weight: 600;
}



body::-webkit-scrollbar {
   width: 7px;
}

body::-webkit-scrollbar-thumb {
   background: #29D300;
   border-radius: 10px;
}

body::-webkit-scrollbar-track {
   background: transparent;
}

#root {
   display: flex;
   flex-wrap: wrap;
   width: 100%;
}

.section {
   position: relative;
}

.section::after {
   content: "";
   display: block;
   width: calc(100% - 19px);
   height: 10px;
   position: absolute;
   top: 0;
   left: 9.5px;
   background: #29D300;
}

@media screen and (max-width: 1199px) {
   .section::after {
      width: calc(100% - 10px);
      left: 5px;
      height: 8px;
   }
}

@media screen and (max-width: 767px) {
   .section::after {
      width: calc(100% - 15px);
      left: 7.5px;
   }
}

.section__left_title {
   padding-top: 17px;
}

@media screen and (max-width: 1600px) {
   .section__left_title {
      padding-top: 14px;
   }
}

@media screen and (max-width: 1199px) {
   .section__left_title {
      padding-top: 16px;
   }
}

.section__left_title h1 {
   color: #23482C;
   font-family: "Spektra", sans-serif;
   font-size: 112px;
   font-style: normal;
   font-weight: 200;
   line-height: 107%;
   text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
   .section__left_title h1 {
      font-size: 88px;
      line-height: 102%;
      padding-top: 0px;
   }
}

@media screen and (max-width: 1199px) {
   .section__left_title h1 {
      font-size: 50px;
      line-height: 96%;
   }
}

.section__left_title h2 {
   color: #23482C;
   font-family: "Spektra", sans-serif;
   font-size: 112px;
   font-style: normal;
   font-weight: 200;
   line-height: 107%;
   text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
   .section__left_title h2 {
      font-size: 88px;
      line-height: 102%;
   }
}

@media screen and (max-width: 1199px) {
   .section__left_title h2 {
      font-size: 50px;
      line-height: 96%;
   }
}

body.green {
   background: #23482C;
}

.hor_grid {
   width: 100%;
   position: absolute;
   top: 0;
   left: 0;
   -webkit-user-select: none;
   -moz-user-select: none;
   user-select: none;
   pointer-events: none;
   z-index: 100;
}

.hor_grid__item {
   height: 10px;
}

@media screen and (max-width: 1199px) {
   .hor_grid__item {
      height: 8px;
   }
}

.hor_grid__item:nth-child(odd) {
   background-color: gray;
   opacity: 0.6;
}

.container {
   width: 100%;
   max-width: 100%;
   margin: 0 auto;
   padding: 0 30px 0 22px;
}

@media screen and (max-width: 1600px) {
   .container {
      padding-left: 20px;
      padding-right: 30px;
   }
}

@media screen and (max-width: 1199px) {
   .container {
      max-width: 100%;
      padding: 0 17px;
   }
}

@media screen and (max-width: 767px) {
   .container {
      max-width: 100%;
      padding: 0 12px;
   }
}

.row {
   --bs-gutter-x: 19px;
}

@media screen and (max-width: 1199px) {
   .row {
      --bs-gutter-x: 10px;
   }
}

@media screen and (max-width: 767px) {
   .row {
      --bs-gutter-x: 15px;
   }
}

.swiper,
.swiper-slide {
   -webkit-user-select: none;
   -moz-user-select: none;
   user-select: none;
}

.swiper-button-prev::after,
.swiper-button-next::after {
   display: none;
}

main {
   overflow-x: clip;
   width: 100%;
   max-width: 83.229%;
   will-change: transfrom, opacity;
   display: flex;
   min-height: 100dvh;
}

.main_wrap {
   width: 100%;
}

@media screen and (max-width: 1199px) {
   main {
      max-width: 100%;
      margin-top: 64px;
   }
}

.other_brands .section__left_title {
   padding-top: 23px;
}

@media screen and (max-width: 1600px) {
   .other_brands .section__left_title {
      padding-top: 17px;
   }
}

@media screen and (max-width: 1199px) {
   .other_brands .section__left_title {
      padding-top: 16px;
   }
}

.other_brands .section__left_title h2 {
   color: #F2E9D8;
   font-family: "Spektra", sans-serif;
   font-size: 88px;
   font-style: normal;
   font-weight: 200;
   line-height: 107%;
   text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
   .other_brands .section__left_title h2 {
      font-size: 75px;
      line-height: 102%;
   }
}

@media screen and (max-width: 1199px) {
   .other_brands .section__left_title h2 {
      font-size: 50px;
      line-height: 96%;
   }
}

.main_content {
   display: flex;
   flex-direction: column;
   height: 100%;
}

.main_content.index {
   background: #F3EAD9;
   padding-top: 30px;
}

@media screen and (max-width: 1199px) {
   .main_content.index {
      padding-top: 0px;
   }
}

.main_content.green {
   background: #23482C;
   padding-top: 30px;
}

@media screen and (max-width: 1199px) {
   .main_content.green {
      padding-top: 0px;
   }
}

.main_content.green div,
.main_content.green p,
.main_content.green h1,
.main_content.green h2,
.main_content.green h3,
.main_content.green a {
   color: #F2E9D8 !important;
}

.main_content.green .section::after {
   background: #F2E9D8;
}

.main_content.black {
   background: #1C1C1C;
   padding-top: 30px;
}

@media screen and (max-width: 1199px) {
   .main_content.black {
      padding-top: 0px;
   }
}

.main_content.black div,
.main_content.black p,
.main_content.black h1,
.main_content.black h2,
.main_content.black h3,
.main_content.black a {
   color: #F3EAD9 !important;
}

.main_content.black .section::after {
   background: #F2E9D8;
}

.fade-in {
   opacity: 0;
   transition: .3s;
}

.fade-out {
   opacity: 1;
   transition: .3s;
}

.hidden_reader {
   position: absolute;
   width: 1px;
   height: 1px;
   margin: -1px;
   border: none;
   padding: 0;
   white-space: nowrap;
   clip-path: inset(100%);
   clip: rect(0 0 0 0);
   overflow: hidden;
}

.btn_burger {
   position: relative;
}

.box {
   display: flex;
   flex-direction: column;
   row-gap: 10px;
   padding-top: 25px;
   padding-bottom: 25px;
   line-height: 0px;
   margin-top: 0px !important;
   margin-bottom: 0px !important;
}

@media screen and (max-width: 1199px) {
   .box {
      padding-bottom: 0px;
   }
}

span[aria-live=polite] {
   display: flex;
   flex-direction: column;
   row-gap: 10px;
   width: 100%;
   height: 100%;
   border-radius: 5px;
}

span[aria-live=polite] span {
   border-radius: 0px;
}

span[aria-live=polite] br {
   display: none;
}

.flex-1 {
   width: 100%;
}

.react-loading-skeleton {
   max-height: 100%;
   max-width: 100%;
}

.left_menu nav {
   width: 100%;
}

.left_menu.loading_page {
   background: rgba(245, 245, 245, 1) !important;
}

.main_content.loading_page {
   background: #fff !important;
}

.main_content.loading_page .section::after {
   background: rgba(226, 233, 239, 1) !important;
}

.main_content.loading_page .product_list__item::before {
   background: rgba(226, 233, 239, 1) !important;
}

.lazyload-wrapper {
   position: relative;
   border-radius: 5px;
   overflow: hidden;
}

 .lazyload-wrapper::after {
   content: '';
   display: block;
   width: calc(100% - 4px);
   height: calc(100% - 4px);
   background: #ebebeb;
   border-radius: 5px;
   position: absolute;
   top: 2px;
   left: 2px;
   z-index: 1;
}

.lazyload-wrapper img {
   position: relative;
   z-index: 2;
}

/*

.lazyload-wrapper::before {
   content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    right: 7px;
    width: calc(100% - 14px);
    height: calc(100% - 14px);
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, #ebebeb, #f5f5f5, #ebebeb);
    transform: translateX(-100%);
    animation-name: react-loading-skeleton;
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    z-index: 2;
}

@keyframes react-loading-skeleton {
   0% {
      transform: translateX(-101%);
   }
   100% {
      transform: translateX(101%);
   }
}

.lazyload-wrapper img {
   z-index: 3;
   position: relative;
} */

.lazyload-wrapper span {
   position: absolute;
   width: 100%;
   height: 100%;
   border-radius: 5px;
   z-index: -1;
}

.left_menu nav a,
.left_menu .left_tel a,
.our_brand__right,
.our_values__right,
.index_team__text,
.our_brand__right,
.our_brand_index .brand_list .item h3,
.glad_cooperate__text,
.glad_cooperate__text .links a,
.footer .copy,
.footer .develop,
.brand_list.page .item h3,
.about__text,
.about__item__other_text,
.innocent_head__text,
.product_list .content__text,
.product_list .content__ideal_vine,
.product_list .meta_wrap .meta__item .name,
.product_list .meta_wrap .meta__item .value,
.product_list .content__btn,
.other_brands .sw_other_brands .item h3,
.product_list .meta_wrap .meta__last .value,
.product_list .meta_wrap .meta__last .name,
.product_list .meta_wrap .text {
   font-family: "Nekst", sans-serif;
}

.index_team .marquee .item,
.our_partner__list .item {
   border-radius: 0px !important; 
}

@media screen and (max-width: 767px) {
   .about__second_image {
      border-radius: 5px;
   }
}

.product_list .content__ideal_vine .icon::after {
   display: none !important;
}