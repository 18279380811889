@media screen and (max-width: 1199px) {
   .innocent_head {
      padding-bottom: 27px;
   }
}

.innocent_head h1 {
   width: 502px;
   max-width: 100%;
   height: 100%;
   max-height: 270px;
   overflow: hidden;
   margin-top: 60px;
   margin-bottom: 50px;
}

@media screen and (max-width: 1600px) {
   .innocent_head h1 {
      width: 372px;
      max-height: 200px;
      margin-top: 50px;
   }
}

@media screen and (max-width: 1199px) {
   .innocent_head h1 {
      width: 238px;
      max-height: 128px;
      margin-top: 40px;
      margin-bottom: 0px;
   }
}

@media screen and (max-width: 767px) {
   .innocent_head h1 {
      margin-top: 32px;
   }
}

.innocent_head h1 img,
.innocent_head h1 svg {
   max-height: 100%;
   height: auto !important;
   max-width: 100%;
   width: auto;
}

.innocent_head__text {
   color: #F2E9D8;
   font-size: 26px;
   font-style: normal;
   font-weight: 600;
   line-height: 33px;
   padding-top: 52px;
   padding-bottom: 41px;
}

@media screen and (max-width: 1600px) {
   .innocent_head__text {
      font-size: 22px;
      line-height: 29px;
      padding-top: 47px;
      padding-bottom: 42px;
   }
}

@media screen and (max-width: 1199px) {
   .innocent_head__text {
      font-size: 18px;
      line-height: 24px;
      padding-top: 13px;
      padding-bottom: 0px;
      padding-right: 10px;
   }
}

@media screen and (max-width: 767px) {
   .innocent_head__text {
      padding-right: 0px;
   }
}

.innocent_head .box {
   padding-bottom: 15px;
}