.about h1 {
   color: #23482C;
   font-family: "Spektra", sans-serif;
   font-size: 112px;
   font-style: normal;
   font-weight: 200;
   line-height: 107%;
   margin-top: 17px;
   text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
   .about h1 {
      font-size: 88px;
      line-height: 102%;
      margin-top: 14px;
   }
}

@media screen and (max-width: 1199px) {
   .about h1 {
      font-size: 50px;
      line-height: 96%;
      margin-top: 16px;
   }
}

.about__first_image {
   width: 100%;
   margin-top: 23px;
   border-radius: 10px;
   overflow: hidden;
   aspect-ratio: 773/280;
}

@media screen and (max-width: 1600px) {
   .about__first_image {
      aspect-ratio: 41/15;
      margin-top: 16px;
   }
}

@media screen and (max-width: 1199px) {
   .about__first_image {
      aspect-ratio: 367/132;
   }
}

@media screen and (max-width: 767px) {
   .about__first_image {
      aspect-ratio: 35/32;
   }
}

.about__first_image img {
   height: auto;
}

@media screen and (max-width: 1600px) {
   .about__first_image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
   }
}

.about__text {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 30px;
   margin-top: 24px;
}

@media screen and (max-width: 1600px) {
   .about__text {
      font-size: 18px;
      line-height: 20px;
      margin-top: 33px;
   }
}

@media screen and (max-width: 1199px) {
   .about__text {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      padding-right: 6px;
      margin-top: 11px;
   }
}

@media screen and (max-width: 767px) {
   .about__text {
      margin-top: 10px;
      padding-right: 0px;
   }
}

.about__text p {
   margin-bottom: 19px;
}

@media screen and (max-width: 1600px) {
   .about__text p {
      margin-bottom: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .about__text p {
      margin-bottom: 0px;
   }
}

.about__text p:last-of-type {
   margin-bottom: 0px;
}

.about .second_image {
   margin-top: 28px;
}

@media screen and (max-width: 1600px) {
   .about .second_image {
      margin-top: 37px;
   }
}

@media screen and (max-width: 767px) {
   .about .second_image {
      margin-top: 21px;
   }
}

.about__second_image {
   width: 100%;
   height: 100%;
   overflow: hidden;
   border-radius: 10px;
   aspect-ratio: 773/270;
}

@media screen and (max-width: 1600px) {
   .about__second_image {
      aspect-ratio: 287/100;
   }
}

@media screen and (max-width: 1199px) {
   .about__second_image {
      aspect-ratio: 367/128;
   }
}

@media screen and (max-width: 767px) {
   .about__second_image {
      aspect-ratio: 35/32;
   }
}

.about__second_image img {
   height: auto;
}

@media screen and (max-width: 1600px) {
   .about__second_image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
   }
}

.about__item {
   padding-bottom: 30px;
}

@media screen and (max-width: 1600px) {
   .about__item {
      padding-bottom: 40px;
   }
}

@media screen and (max-width: 1199px) {
   .about__item {
      padding-bottom: 32px;
   }
}

@media screen and (max-width: 767px) {
   .about__item {
      padding-bottom: 32px;
   }
}

@media screen and (max-width: 767px) {
   .about__item .row {
      flex-direction: column-reverse;
      margin-top: 10px;
      row-gap: 21px;
   }
}

.about__item__other_image {
   width: 100%;
   border-radius: 5px;
   overflow: hidden;
   aspect-ratio: 763/510;
   margin-top: 30px;
}

@media screen and (max-width: 1600px) {
   .about__item__other_image {
      margin-top: 40px;
      aspect-ratio: 113/76;
   }
}

@media screen and (max-width: 1199px) {
   .about__item__other_image {
      margin-top: 16px;
      aspect-ratio: 181/120;
   }
}

@media screen and (max-width: 767px) {
   .about__item__other_image {
      border-radius: 10px;
      margin-top: 0px;
      aspect-ratio: 351/320;
   }
}

.about__item__other_image img {
   height: auto;
}

@media screen and (max-width: 1600px) {
   .about__item__other_image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
   }
}

.about__item__other_text {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 30px;
   margin-top: 24px;
}

@media screen and (max-width: 1600px) {
   .about__item__other_text {
      font-size: 18px;
      line-height: 20px;
      margin-top: 43px;
   }
}

@media screen and (max-width: 1199px) {
   .about__item__other_text {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      margin-top: 11px;
   }
}

@media screen and (max-width: 767px) {
   .about__item__other_text {
      margin-top: 0px;
      padding-right: 10px;
   }
}