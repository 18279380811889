.spreadings_head {
   position: relative;
   padding-top: 50px;
   padding-bottom: 80px;
}

.spreadings_head.box {
   padding-top: 25px;
   padding-bottom: 15px;
}

@media screen and (max-width: 1600px) {
   .spreadings_head {
      padding-top: 40px;
      padding-bottom: 60px;
   }
}

@media screen and (max-width: 1199px) {
   .spreadings_head {
      padding-top: 24px;
      padding-bottom: 32px;
   }
}

.spreadings_head h1 {
   width: 890px;
   max-width: 100%;
   height: 100%;
   max-height: 240px;
   overflow: hidden;
   text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
   .spreadings_head h1 {
      width: 672px;
      max-height: 180px;
   }
}

@media screen and (max-width: 1199px) {
   .spreadings_head h1 {
      width: 416px;
      max-height: 112px;
   }
}

@media screen and (max-width: 767px) {
   .spreadings_head h1 {
      width: 305px;
      max-height: 80px;
   }
}

.spreadings_head h1 img,
.spreadings_head h1 svg {
   max-height: 100%;
   height: auto !important;
   max-width: 100%;
   width: auto;
}