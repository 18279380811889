.index_top h1 {
   color: #23482C;
   font-family: "Spektra", sans-serif;
   font-size: 112px;
   font-style: normal;
   font-weight: 200;
   line-height: 107%;
   text-transform: uppercase;
   padding-top: 17px;
   padding-bottom: 23px;
}

@media screen and (max-width: 1600px) {
   .index_top h1 {
      font-size: 88px;
      line-height: 102%;
      padding-top: 14px;
      padding-bottom: 16px;
   }
}

@media screen and (max-width: 1199px) {
   .index_top h1 {
      font-size: 50px;
      line-height: 96%;
      padding-top: 16px;
      padding-bottom: 16px;
   }
}

@media screen and (max-width: 767px) {
   .index_top h1 {
      padding-top: 16px;
      padding-bottom: 16px;
   }
}