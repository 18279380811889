.our_brand_index {
   padding-bottom: 114px;
}

@media screen and (max-width: 1600px) {
   .our_brand_index {
      padding-bottom: 47px;
   }
}

@media screen and (max-width: 1199px) {
   .our_brand_index {
      padding-bottom: 29px;
   }
}

.our_brand_index .brand_list {
   margin-top: 57px;
}

@media screen and (max-width: 1600px) {
   .our_brand_index .brand_list {
      margin-top: 37px;
   }
}

@media screen and (max-width: 1199px) {
   .our_brand_index .brand_list {
      margin-top: 29px;
   }
}

@media screen and (max-width: 767px) {
   .our_brand_index .brand_list {
      margin-top: 13px;
      row-gap: 21px;
   }
}

.our_brand_index .brand_list .item {
   display: flex;
   flex-direction: column;
   row-gap: 20px;
}

@media screen and (max-width: 1600px) {
   .our_brand_index .brand_list .item {
      row-gap: 23px;
   }
}

@media screen and (max-width: 1199px) {
   .our_brand_index .brand_list .item {
      row-gap: 10px;
   }
}

.our_brand_index .brand_list .item .image {
   width: 100%;
   height: auto;
   overflow: hidden;
   aspect-ratio: 1/0.9994;
   border-radius: 5px;
}

@media (hover: hover) {
   .our_brand_index .brand_list .item .image:hover img {
      transform: scale(1.03);
   }
}

@media (hover: none) {
   .our_brand_index .brand_list .item .image:active img {
      transform: scale(1.03);
   }
}

@media screen and (max-width: 1600px) {
   .our_brand_index .brand_list .item .image {
      aspect-ratio: 1/0.98953;
   }
}

@media screen and (max-width: 1199px) {
   .our_brand_index .brand_list .item .image {
      aspect-ratio: 1/1;
   }
}

.our_brand_index .brand_list .item .image img {
   height: auto;
   border-radius: 5px;
   transition: 0.55s;
}

@media screen and (max-width: 1600px) {
   .our_brand_index .brand_list .item .image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
   }
}

.our_brand_index .brand_list .item h3 {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   text-transform: uppercase;
   transition: 0.3s;
}

@media (hover: hover) {
   .our_brand_index .brand_list .item h3:hover {
      color: #29D300 !important;
   }
}

@media (hover: none) {
   .our_brand_index .brand_list .item h3:active {
      color: #29D300 !important;
   }
}

@media screen and (max-width: 1600px) {
   .our_brand_index .brand_list .item h3 {
      font-size: 18px;
      line-height: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .our_brand_index .brand_list .item h3 {
      font-size: 14px;
      line-height: normal;
   }
}

.our_brand_index .brand_list .item .box {
   padding-top: 0px;
   padding-bottom: 0px;
}

.our_brand_index .brand_list .item .box:first-of-type {
   aspect-ratio: 1/0.9994;
}

.our_brand_index .brand_list .item span[aria-live=polite] {
   row-gap: 0px;
}