.map_contact {
   padding-top: 40px;
   padding-bottom: 30px;
   flex: 1 1 auto;
}

@media screen and (max-width: 1600px) {
   .map_contact {
      padding-top: 30px;
      padding-bottom: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .map_contact {
      padding-top: 24px;
      padding-bottom: 16px;
   }
}

.map_contact .col-12 {
   height: 100%;
}

.map_contact .map {
   width: 100%;
   height: 730px;
   overflow: hidden;
   min-height: 100%;
}

@media screen and (max-width: 1600px) {
   .map_contact .map {
      height: 490px;
   }
}

@media screen and (max-width: 1199px) {
   .map_contact .map {
      aspect-ratio: unset;
      height: 368px;
   }
}

.map_contact .map .ymaps-2-1-79-map-copyrights-promo {
   display: none !important;
}

.map_contact .map .ymaps-2-1-79-copyright__wrap {
   display: none !important;
}

.map_contact .box {
   height: 730px;
   padding-top: 0px;
   padding-bottom: 0px;
   min-height: 100%;
}

@media screen and (max-width: 1600px) {
   .map_contact .box {
      height: 490px;
   }
}

@media screen and (max-width: 1199px) {
   .map_contact .box {
      height: 368px;
   }
}