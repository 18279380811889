@font-face {
   font-family: "Spektra";
   src: url("./fonts/Spektra/Spektra-Regular.woff2") format("woff2");
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Nekst";
   src: url("./fonts/Nekst/Nekst-Regular.woff2") format("woff2");
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: "Nekst";
   src: url("./fonts/Nekst/Nekst-SemiBold.woff2") format("woff2");
   font-weight: 600;
   font-style: normal;
   font-display: swap;
}