.our_brand {
   padding-bottom: 57px;
}

@media screen and (max-width: 1600px) {
   .our_brand {
      padding-bottom: 37px;
   }
}

@media screen and (max-width: 1199px) {
   .our_brand {
      padding-bottom: 21px;
   }
}

@media screen and (max-width: 767px) {
   .our_brand {
      padding-bottom: 29px;
   }
}

.our_brand__right {
   display: flex;
   font-family: "Nekst", sans-serif;
   flex-direction: column;
   row-gap: 30px;
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 30px;
   /* 107.143% */
   margin-top: 33px;
}

.our_brand__right p {
   font-family: inherit;
}

@media screen and (max-width: 1600px) {
   .our_brand__right {
      font-size: 18px;
      line-height: 20px;
      margin-top: 23px;
      row-gap: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .our_brand__right {
      font-size: 14px;
      line-height: 16px;
      margin-top: 19px;
      row-gap: 16px;
   }
}

@media screen and (max-width: 767px) {
   .our_brand__right {
      padding-right: 17px;
      margin-top: 11px;
   }
}