.index_team {
   padding-bottom: 50px;
   row-gap: 57px;
}

@media screen and (max-width: 1600px) {
   .index_team {
      row-gap: 27px;
      padding-bottom: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .index_team {
      row-gap: 13px;
      padding-bottom: 16px;
   }
}

@media screen and (max-width: 767px) {
   .index_team {
      padding-bottom: 8px;
   }
}

.index_team__text {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 30px;
   margin-top: 33px;
}

@media screen and (max-width: 1600px) {
   .index_team__text {
      font-size: 18px;
      line-height: 20px;
      margin-top: 23px;
   }
}

@media screen and (max-width: 1199px) {
   .index_team__text {
      font-size: 14px;
      line-height: 16px;
      margin-top: 19px;
   }
}

.index_team .marquee {
   overflow: visible;
}


.index_team .marquee .rfm-marquee-container {
   column-gap: 3px;
   overflow: visible;
}

.index_team .marquee .rfm-marquee .rfm-initial-child-container {
   column-gap: 3px;
}

.rfm-marquee {
   min-width: auto;
}

.index_team .marquee .item {
   height: 80px;
}

@media screen and (max-width: 1600px) {
   .index_team .marquee .item {
      height: 60px;
   }
}

@media screen and (max-width: 1199px) {
   .index_team .marquee .item {
      height: 32px;
   }
}

@media screen and (max-width: 767px) {
   .index_team .marquee .item {
      height: 24px;
   }
}

.index_team .marquee .item img {
   max-height: 100%;
   height: 100%;
   width: auto;
}