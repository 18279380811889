.product_list.snacks .image {
   aspect-ratio: 502.65/520;
}

@media screen and (max-width: 1600px) {
   .product_list.snacks .image {
      aspect-ratio: 502.65/516.02;
   }
}

@media screen and (max-width: 1199px) {
   .product_list.snacks .image {
      aspect-ratio: 119/124;
   }
}

@media screen and (max-width: 767px) {
   .product_list.snacks .image {
      aspect-ratio: 39/40;
   }
}

.product_list.snacks .meta_wrap .meta {
   padding-top: 158px;
}

@media screen and (max-width: 1600px) {
   .product_list.snacks .meta_wrap .meta {
      padding-top: 133px;
      row-gap: 18px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list.snacks .meta_wrap .meta {
      padding-top: 24px;
      row-gap: 6px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list.snacks .meta_wrap {
      background: #1C1C1C;
   }

   .product_list.snacks .content__text {
      padding-right: 65px;
   }

   .product_list.snacks .content__title {
      width: 100%;
      max-width: calc(100% - 190px);
   }
}

@media screen and (max-width: 767px) {
   .product_list.snacks .content__title {
      width: 100%;
      max-width: 100%;
   }

   .product_list.snacks .content__text {
      padding-right: 0px;
   }
}

.product_list__item {
   position: relative;
}

.product_list__item::before {
   content: "";
   display: block;
   width: calc(100% - 19px);
   height: 10px;
   position: absolute;
   top: 0px;
   left: 9.5px;
   background: #F2E9D8;
}

@media screen and (max-width: 1199px) {
   .product_list__item::before {
      width: calc(100% - 10px);
      left: 5px;
      height: 8px;
   }
}

@media screen and (max-width: 767px) {
   .product_list__item::before {
      width: calc(100% - 15px);
      left: 7.5px;
      height: 8px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list__item .col-xl-3 {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      width: 100%;
      height: 100%;
      padding: 20px 17px;
      z-index: 99;
      top: 0px;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      opacity: 0;
      visibility: hidden;
      transition: 0.4s;
      transition-delay: 0.25s;
   }

   .product_list__item .col-xl-3.active {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
   }

   .product_list__item .col-xl-3.active .meta_wrap {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition-delay: 0.25s;
   }
}

@media screen and (max-width: 767px) {
   .product_list__item .col-xl-3 {
      padding: 12px;
   }
}

.product_list__item .image {
   margin-top: 40px;
   margin-bottom: 40px;
   width: 100%;
   height: auto;
   aspect-ratio: 502.65/500;
   overflow: hidden;
   border-radius: 5px;
}

@media screen and (max-width: 1600px) {
   .product_list__item .image {
      aspect-ratio: 1/0.9996;
      margin-bottom: 30px;
      margin-top: 30px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list__item .image {
      aspect-ratio: 119/120;
      margin-top: 24px;
      margin-bottom: 16px;
   }
}

@media screen and (max-width: 767px) {
   .product_list__item .image {
      aspect-ratio: 351/352;
      margin-bottom: 0px;
   }
}

.product_list__item .image img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: 5px;
}

@media screen and (max-width: 1600px) {
   .product_list__item .image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
   }
}

.product_list .content {
   padding-top: 22px;
   padding-bottom: 35px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
   padding-right: 15px;
   row-gap: 30px;
}

@media screen and (max-width: 1600px) {
   .product_list .content {
      padding-top: 17px;
      padding-bottom: 27px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list .content {
      padding-top: 16px;
      padding-bottom: 13px;
      justify-content: flex-start;
      padding-right: 0px;
      row-gap: 0px;
   }
}

@media screen and (max-width: 767px) {
   .product_list .content {
      padding-top: 8px;
      padding-bottom: 21px;
   }
}

.product_list .content__title {
   color: #F2E9D8;
   font-family: "Spektra", sans-serif;
   font-size: 88px;
   font-style: normal;
   font-weight: 200;
   line-height: 107%;
   text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
   .product_list .content__title {
      font-size: 75px;
      line-height: 102%;
   }
}

@media screen and (max-width: 1199px) {
   .product_list .content__title {
      font-size: 50px;
      line-height: 96%;
   }
}

.product_list .content__text {
   color: #F2E9D8;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 27px;
}

@media screen and (max-width: 1600px) {
   .product_list .content__text {
      font-size: 16px;
      line-height: 21px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list .content__text {
      font-size: 14px;
      line-height: 16px;
      margin-top: 19px;
   }
}

@media screen and (max-width: 767px) {
   .product_list .content__text {
      margin-top: 11px;
   }
}

.product_list .content__btn {
   display: none;
   max-width: -webkit-fill-available;
   max-width: -moz-fit-content;
   max-width: fit-content;
}

@media screen and (max-width: 1199px) {
   .product_list .content__btn {
      display: inline-flex;
      margin-top: 16px;
      color: #F3EAD9;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-decoration-line: underline;
      text-underline-offset: 2px;
      transition: 0.3s;
   }
}

@media screen and (max-width: 1199px) and (hover: hover) {
   .product_list .content__btn:hover {
      color: #29D300 !important;
   }
}

@media screen and (max-width: 1199px) and (hover: none) {
   .product_list .content__btn:active {
      color: #29D300 !important;
   }
}

@media screen and (max-width: 767px) {
   .product_list .content__btn {
      margin-top: 16px;
   }
}

.product_list .content__ideal_vine {
   display: flex;
   align-items: center;
   -moz-column-gap: 14px;
   column-gap: 14px;
   color: #F2E9D8;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 27px;
   text-transform: uppercase;
   position: absolute;
   top: 40px;
   right: 8.5px;
   width: 100%;
   max-width: calc(25% - 19px);
}

@media screen and (max-width: 1600px) {
   .product_list .content__ideal_vine {
      font-size: 16px;
      line-height: 21px;
      top: 30px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list .content__ideal_vine {
      font-size: 12px;
      line-height: 17px;
      top: 24px;
      -moz-column-gap: 7px;
      column-gap: 7px;
      width: 100%;
      max-width: 176px;
   }
}

@media screen and (max-width: 767px) {
   .product_list .content__ideal_vine {
      display: none;
   }
}

.product_list .content__ideal_vine .icon {
   display: flex;
   width: 70px;
   height: 70px;
   max-width: 70px;
   max-height: 70px;
   min-width: 70px;
   min-height: 70px;
   overflow: hidden;
}

@media screen and (max-width: 1600px) {
   .product_list .content__ideal_vine .icon {
      width: 60px;
      height: 60px;
      max-width: 60px;
      max-height: 60px;
      min-width: 60px;
      min-height: 60px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list .content__ideal_vine .icon {
      width: 40px;
      height: 40px;
      max-width: 40px;
      max-height: 40px;
      min-width: 40px;
      min-height: 40px;
   }
}

.product_list .content__ideal_vine img,
.product_list .content__ideal_vine svg {
   width: 100%;
   height: 100%;
   max-width: 100%;
   max-height: 100%;
   -o-object-fit: contain;
   object-fit: contain;
}

.product_list .meta_wrap {
   height: 100%;
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap {
      width: 100%;
      max-width: 486px;
      background: #23482C;
      padding: 42px 17px;
      position: relative;
      max-height: 100%;
      overflow-y: auto;
      opacity: 0;
      visibility: hidden;
      transition: 0.4s;
      transform: translateY(8px);
      transition-delay: 0s;
      height: auto;
   }
}

.product_list .meta_wrap::-webkit-scrollbar {
   width: 0px;
}

.product_list .meta_wrap .close {
   display: none;
}

.product_list .meta_wrap .close svg {
   user-select: none;
   pointer-events: none;
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap .close {
      display: flex;
      position: absolute;
      top: 8px;
      right: 10px;
   }
}

.product_list .meta_wrap .title {
   display: none;
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap .title {
      display: block;
      color: #F3EAD9;
      font-family: "Spektra", sans-serif;
      font-size: 50px;
      font-style: normal;
      font-weight: 200;
      line-height: 96%;
      text-transform: uppercase;
      padding-right: 35px;
   }
}

.product_list .meta_wrap .text {
   display: none;
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap .text {
      display: block;
      color: #F3EAD9;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-top: 12px;
   }
}

.product_list .meta_wrap .meta {
   padding-top: 38px;
   padding-bottom: 35px;
   display: flex;
   flex-direction: column;
   height: 100%;
   row-gap: 24px;
}

@media screen and (max-width: 1600px) {
   .product_list .meta_wrap .meta {
      row-gap: 18px;
      padding-top: 22px;
      padding-bottom: 27px;
      row-gap: 6px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap .meta {
      padding: 24px 0 0 0;
   }
}

.product_list .meta_wrap .meta__item {
   display: flex;
   -moz-column-gap: 10px;
   column-gap: 10px;
   justify-content: space-between;
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap .meta__item {
      -moz-column-gap: 20px;
      column-gap: 20px;
      justify-content: flex-start;
   }
}

.product_list .meta_wrap .meta__item .name {
   color: #F2E9D8;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 27px;
   width: calc(100% - 120px);
}

@media screen and (max-width: 1600px) {
   .product_list .meta_wrap .meta__item .name {
      font-size: 16px;
      line-height: 21px;
      width: calc(100% - 97px);
   }
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap .meta__item .name {
      font-size: 14px;
      line-height: 18px;
      width: 150px;
   }
}

.product_list .meta_wrap .meta__item .value {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   width: 110px;
   color: #F2E9D8;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 27px;
}

@media screen and (max-width: 1600px) {
   .product_list .meta_wrap .meta__item .value {
      font-size: 16px;
      line-height: 21px;
      width: 78px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap .meta__item .value {
      font-size: 14px;
      line-height: 18px;
      max-width: calc(100% - 150px - 20px);
   }
}

.product_list .meta_wrap .meta__item .value span:nth-of-type(2) {
   border-top: 1px solid #F2E9D8;
}

.product_list .meta_wrap .meta__last {
   display: flex;
   align-items: flex-end;
   flex: 1 1 auto;
   -moz-column-gap: 19px;
   column-gap: 19px;
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap .meta__last {
      flex-direction: column;
      row-gap: 6px;
      justify-content: flex-start;
      align-items: flex-start;
   }
}

.product_list .meta_wrap .meta__last .value {
   color: #F2E9D8;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 27px;
   width: 111px;
}

@media screen and (max-width: 1600px) {
   .product_list .meta_wrap .meta__last .value {
      font-size: 16px;
      line-height: 21px;
      width: 100%;
      max-width: 79px;
   }
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap .meta__last .value {
      font-size: 14px;
      line-height: 18px;
   }
}

.product_list .meta_wrap .meta__last .name {
   width: calc(100% - 130px);
   color: #F2E9D8;
   font-size: 20px;
   font-style: normal;
   font-weight: 400;
   line-height: 27px;
}

@media screen and (max-width: 1600px) {
   .product_list .meta_wrap .meta__last .name {
      font-size: 16px;
      line-height: 21px;
      width: 100%;
      max-width: calc(100% - 97px);
   }
}

@media screen and (max-width: 1199px) {
   .product_list .meta_wrap .meta__last .name {
      font-size: 14px;
      line-height: 18px;
   }
}

.product_list__item .col-md-4 .image.box {
   padding: 0px;
   margin: 0px;
   margin-bottom: 15px !important;
   margin-top: 25px !important;
}

.product_list__item .col-xl-5 .box {
   justify-content: space-between;
   row-gap: 20px;
   padding: 0px;
   margin: 0px;
   padding-bottom: 15px;
   padding-top: 25px;
}

.product_list__item .col-xl-5 .box span {
   height: auto;
}

.product_list .product_list__item .meta_wrap .meta.box {
   row-gap: 10px;
   padding: 0px;
   margin: 0px;
   padding-bottom: 15px;
   padding-top: 25px;
}

.product_list .product_list__item .meta_wrap .meta.box .meta__last {
   row-gap: 10px;
}

.product_list.snacks .meta_wrap .meta.box {
   padding-top: 125px;
}

.product_list .content__ideal_vine.box {
   flex-direction: row;
   justify-content: flex-start;
   top: 0px;
}

.product_list .content__ideal_vine .icon span {
   height: 100%;
   width: 100%;
}



@media screen and (max-width: 1199px) {
   .product_list__item .col-xl-5 .box {
      row-gap: 20px;
      justify-content: flex-start;
   }

   .product_list__item .col-xl-5 .box .content__btn {
      margin-top: 0px;
   }

   .product_list__item .col-xl-5 .box .first {
      max-width: calc(100% - 190px);
      height: 40px;
   }

   .product_list__item .col-xl-5 .box .vine span {
      width: 100% !important;
      height: 10px !important;
   }
}

@media screen and (max-width: 767px) {

   .product_list__item .col-xl-5 .box .first {
      max-width: 100%;
   }

   .product_list__item .col-xl-5 .box {
      padding-bottom: 25px;
   }
}