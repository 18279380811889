.our_values {
   row-gap: 46px;
   margin-bottom: 30px;
}

@media screen and (max-width: 1600px) {
   .our_values {
      margin-bottom: 21px;
      row-gap: 37px;
   }
}

@media screen and (max-width: 1199px) {
   .our_values {
      row-gap: 13px;
      margin-bottom: 16px;
   }
}

@media screen and (max-width: 767px) {
   .our_values {
      row-gap: 0px;
   }
}

.our_values__right {
   display: flex;
   flex-direction: column;
   row-gap: 30px;
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 30px;
   margin-top: 34px;
   margin-bottom: 0px;
}

@media screen and (max-width: 1600px) {
   .our_values__right {
      font-size: 18px;
      line-height: 20px;
      row-gap: 20px;
      margin-top: 23px;
   }
}

@media screen and (max-width: 1199px) {
   .our_values__right {
      font-size: 14px;
      line-height: 16px;
      row-gap: 16px;
      margin-top: 19px;
   }
}

@media screen and (max-width: 767px) {
   .our_values__right {
      margin-top: 11px;
   }
}

.our_values__image {
   width: 100%;
   height: 100%;
   border-radius: 5px;
   overflow: hidden;
   aspect-ratio: 773/300;
}

.our_values .col-12 .box {
   aspect-ratio: 773/300;
}

@media screen and (max-width: 1600px) {
   .our_values__image {
      aspect-ratio: 1148/529.78;
   }

   .our_values .col-12 .box {
      aspect-ratio: 1148/529.78;
   }
}

@media screen and (max-width: 1199px) {
   .our_values__image {
      aspect-ratio: 367/168;
   }

   .our_values .col-12 .box {
      aspect-ratio: 367/168;
   }
}

@media screen and (max-width: 767px) {
   .our_values__image {
      aspect-ratio: 351/232;
      margin-top: 0px;
   }

   .our_values .col-12 .box {
      aspect-ratio: 351/232;
   }
}


.our_values__image .lazyload-wrapper {
   width: 100%;
   height: 100%;
}

@media screen and (max-width: 1600px) {
   .our_values__image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
   }
}

@media screen and (max-width: 767px) {
   .our_values .col-12 {
      margin-top: 13px;
   }
}