.footer {
   padding-top: 28px;
   padding-bottom: 23px;
}

@media screen and (max-width: 1600px) {
   .footer {
      padding-top: 33px;
      padding-bottom: 24px;
   }
}

@media screen and (max-width: 1199px) {
   .footer {
      padding-top: 19px;
      padding-bottom: 13px;
   }
}

@media screen and (max-width: 767px) {
   .footer {
      row-gap: 8px;
      padding-bottom: 21px;
   }
}

.footer .copy {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 39px;
}

@media screen and (max-width: 1600px) {
   .footer .copy {
      font-size: 18px;
      line-height: 22px;
   }
}

@media screen and (max-width: 1199px) {
   .footer .copy {
      font-size: 14px;
      line-height: 16px;
   }
}

.footer .develop {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 39px;
}

@media screen and (max-width: 1600px) {
   .footer .develop {
      font-size: 18px;
      line-height: 22px;
   }
}

@media screen and (max-width: 1199px) {
   .footer .develop {
      font-size: 14px;
      line-height: 16px;
   }
}

.footer .develop a {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 39px;
   transition: 0.3s;
}

@media (hover: hover) {
   .footer .develop a:hover {
      color: #29D300 !important;
   }
}

@media (hover: none) {
   .footer .develop a:active {
      color: #29D300 !important;
   }
}

@media screen and (max-width: 1600px) {
   .footer .develop a {
      font-size: 18px;
      line-height: 22px;
   }
}

@media screen and (max-width: 1199px) {
   .footer .develop a {
      font-size: 14px;
      line-height: 16px;
   }
}