.our_partner {
   padding-bottom: 30px;
}

@media screen and (max-width: 1600px) {
   .our_partner {
      padding-bottom: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .our_partner {
      padding-bottom: 16px;
   }
}

.our_partner__list {
   margin-top: 40px;
   display: flex;
   flex-wrap: wrap;
   -moz-column-gap: 10px;
   column-gap: 10px;
   row-gap: 10px;
}

@media screen and (max-width: 1600px) {
   .our_partner__list {
      margin-top: 30px;
   }
}

@media screen and (max-width: 1199px) {
   .our_partner__list {
      -moz-column-gap: 8px;
      column-gap: 8px;
      row-gap: 8px;
      margin-top: 24px;
   }
}

@media screen and (max-width: 767px) {
   .our_partner__list {
      margin-top: 16px;
   }
}

.our_partner__list .item {
   width: auto;
   height: 80px;
   max-height: 80px;
}

@media screen and (max-width: 1600px) {
   .our_partner__list .item {
      height: 60px;
      max-height: 60px;
   }
}

@media screen and (max-width: 1199px) {
   .our_partner__list .item {
      height: 32px;
      max-height: 32px;
   }
}

.our_partner__list .item img {
   max-height: 100%;
   height: auto;
   width: auto;
}