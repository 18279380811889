.glad_cooperate {
   padding-bottom: 26px;
}

@media screen and (max-width: 1600px) {
   .glad_cooperate {
      padding-bottom: 27px;
   }
}

@media screen and (max-width: 1199px) {
   .glad_cooperate {
      padding-bottom: 37px;
   }
}

@media screen and (max-width: 767px) {
   .glad_cooperate {
      padding-bottom: 13px;
   }
}

.glad_cooperate.contact {
   padding-bottom: 28px;
   flex: unset;
}

.glad_cooperate.contact .links {
   margin-top: 17px;
}

@media screen and (max-width: 1600px) {
   .glad_cooperate.contact {
      padding-bottom: 37px;
   }

   .glad_cooperate.contact .links {
      margin-top: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .glad_cooperate.contact {
      padding-bottom: 13px;
   }

   .glad_cooperate.contact .links {
      margin-top: 8px;
   }
}

.glad_cooperate.contact .glad_cooperate__text {
   margin-top: 29px;
}

@media screen and (max-width: 1600px) {
   .glad_cooperate.contact .glad_cooperate__text {
      margin-top: 33px;
   }
}

@media screen and (max-width: 1199px) {
   .glad_cooperate.contact .glad_cooperate__text {
      margin-top: 19px;
   }
}

.glad_cooperate__text {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 39px;
   margin-top: 31px;
}

@media screen and (max-width: 1600px) {
   .glad_cooperate__text {
      margin-top: 33px;
   }
}

@media screen and (max-width: 1199px) {
   .glad_cooperate__text {
      padding-right: 10px;
      margin-top: 19px;
   }
}

@media screen and (max-width: 767px) {
   .glad_cooperate__text {
      margin-top: 11px;
      max-width: 400px;
   }
}

.glad_cooperate__text p {
   margin-bottom: 20px;
}

.glad_cooperate__text p:last-of-type {
   margin-bottom: 0px;
}

@media screen and (max-width: 1600px) {
   .glad_cooperate__text p {
      font-size: 18px;
      line-height: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .glad_cooperate__text p {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 16px;
   }
}

.glad_cooperate__text .links {
   display: flex;
   flex-direction: column;
   margin-top: 7px;
   row-gap: 10px;
}

@media screen and (max-width: 1600px) {
   .glad_cooperate__text .links {
      margin-top: 10px;
   }
}

@media screen and (max-width: 1199px) {
   .glad_cooperate__text .links {
      margin-top: 8px;
      row-gap: 8px;
   }
}

.glad_cooperate__text .links a {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: 30px;
   transition: 0.3s;
}

@media (hover: hover) {
   .glad_cooperate__text .links a:hover {
      color: #29D300 !important;
   }
}

@media (hover: none) {
   .glad_cooperate__text .links a:active {
      color: #29D300 !important;
   }
}

@media screen and (max-width: 1600px) {
   .glad_cooperate__text .links a {
      font-size: 18px;
      line-height: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .glad_cooperate__text .links a {
      font-size: 14px;
      line-height: 16px;
   }
}