.barnd_page_top h1 {
   color: #F2E9D8;
   font-family: "Spektra", sans-serif;
   font-size: 300px;
   font-style: normal;
   font-weight: 200;
   line-height: 107%;
   margin-top: -12px;
   margin-bottom: 21px;
   text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
   .barnd_page_top h1 {
      font-size: 200px;
      line-height: 102%;
      margin-top: 3px;
      margin-bottom: 23px;
   }
}

@media screen and (max-width: 1199px) {
   .barnd_page_top h1 {
      font-size: 80px;
      line-height: 96%;
      margin-top: 12px;
      margin-bottom: 15px;
   }
}

.brand_list.page {
   padding-top: 40px;
   padding-bottom: 44px;
}

@media screen and (max-width: 1600px) {
   .brand_list.page {
      padding-top: 30px;
      padding-bottom: 77px;
   }
}

@media screen and (max-width: 1199px) {
   .brand_list.page {
      padding-top: 24px;
      padding-bottom: 43px;
   }
}

@media screen and (max-width: 767px) {
   .brand_list.page {
      row-gap: 44px;
      padding-bottom: 36px;
   }
}

.brand_list.page .item {
   display: flex;
   flex-direction: column;
   row-gap: 20px;
}

@media screen and (max-width: 1600px) {
   .brand_list.page .item {
      row-gap: 23px;
   }
}

@media screen and (max-width: 1199px) {
   .brand_list.page .item {
      row-gap: 12px;
   }
}

@media screen and (max-width: 767px) {
   .brand_list.page .item {
      row-gap: 12px;
   }
}

.brand_list.page .item .image {
   width: 100%;
   height: auto;
   overflow: hidden;
   aspect-ratio: 1/0.9994;
}

@media (hover: hover) {
   .brand_list.page .item .image:hover img {
      transform: scale(1.03);
   }
}

@media (hover: none) {
   .brand_list.page .item .image:active img {
      transform: scale(1.03);
   }
}

@media screen and (max-width: 1600px) {
   .brand_list.page .item .image {
      aspect-ratio: 1/0.98953;
   }
}

@media screen and (max-width: 1199px) {
   .brand_list.page .item .image {
      aspect-ratio: 1/1;
   }
}

@media screen and (max-width: 767px) {
   .brand_list.page .item .image {
      aspect-ratio: 1/1.00285;
   }
}

.brand_list.page .item .image img {
   height: auto;
   border-radius: 5px;
   transition: 0.55s;
}

@media screen and (max-width: 1600px) {
   .brand_list.page .item .image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
   }
}

.brand_list.page .item h3 {
   color: #23482C;
   font-size: 28px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   text-transform: uppercase;
   transition: 0.3s;
}

@media (hover: hover) {
   .brand_list.page .item h3:hover {
      color: #29D300 !important;
   }
}

@media (hover: none) {
   .brand_list.page .item h3:active {
      color: #29D300 !important;
   }
}

@media screen and (max-width: 1600px) {
   .brand_list.page .item h3 {
      font-size: 18px;
      line-height: 20px;
   }
}

@media screen and (max-width: 1199px) {
   .brand_list.page .item h3 {
      font-size: 14px;
      line-height: normal;
   }
}

@media screen and (max-width: 767px) {
   .brand_list.page .item h3 {
      font-size: 20px;
      line-height: normal;
   }
}